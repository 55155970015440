@font-face {
  font-family: "Montserrat-Arabic-Bold";
  src: url("../fonts/Montserrat-Arabic-Bold.otf") format("opentype"),
    url("../fonts/Montserrat-Arabic-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "Montserrat-Arabic-SemiBold";
  src: url("../fonts/Montserrat-Arabic-SemiBold.otf") format("opentype"),
    url("../fonts/Montserrat-Arabic-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: "Montserrat-Arabic";
  src: url("../fonts/Montserrat-Arabic-Medium.otf") format("opentype"),
    url("../fonts/Montserrat-Arabic-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: "Montserrat-Arabic-Light";
  src: url("../fonts/Montserrat-Arabic-Light.otf") format("opentype"),
    url("../fonts/Montserrat-Arabic-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: block;
}
